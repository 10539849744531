.search-title {
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.no-products-found {
  text-align: center;
  margin-top: 10rem;
  font-size: 1.6rem;
}

@media (min-width: 1024px) {
  .search-title {
    margin-top: 4rem;
    margin-left: 6.5rem;
    text-align: left;
  }

  .container-products {
    margin-top: 4rem;
    text-align: left;
  }
}
