/*Mobile First*/

.product-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.product-detailed-image {
  max-width: 100%;
  height: auto;
}

.product-detailed-items {
  text-align: left;
  margin-left: 3rem;
  width: 100%;
  margin-bottom: 4rem;
}

.product-detailed-price {
  font-size: 1.3rem;
  color: #fb5e0e;
  font-weight: bold;
  margin-top: 0.5rem;
}

.product-detailed-description {
  width: 80%;
}

.link-button {
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 140px;
  height: 35px;
  background-color: #fb5e0e;
  color: white;
  font-size: 0.9rem;
  margin-top: 2rem;
}

.product-detailed-name {
  font-size: 1.3rem;
  margin-top: 1.5rem;
  font-weight: bold;
  color: #323232;
  margin-top: 0.5rem;
}

@media only screen and (min-width: 1024px) {
  .product-detail {
    flex-direction: row;
    margin-top: 4rem;
    margin-left: 10rem;
  }

  .product-detailed-name {
    font-size: 1.6rem;
    margin-top: 1rem;
  }

  .image-container img {
    width: 700px;
    height: 400px;
  }

  .product-detailed-category {
    font-size: 1rem;
    color: #686868;
  }

  .product-detailed-price {
    margin-top: 2rem;
  }

  .product-detailed-description {
    font-size: 1.1rem;
  }

  .link-button {
    margin-top: 2rem;
  }
}
