/*Mobile First*/

.nav-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.logo-and-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
}

.logo {
  margin-left: 1rem;
}

.menu-toggle {
  display: block;
  margin-right: 1rem;
  background-color: #f6efef;
  color: #ada8a9;
  font-size: 1.25rem;
  padding: 10px;
  border-radius: 10px;
}

::placeholder {
  font-size: 1rem;
  font-weight: 600;
  color: white;
}

.search-icon {
  margin-left: -2rem;
  color: white;
  cursor: pointer;
}

.search-bar input {
  max-width: 100%;
  color: white;
  padding: 0.5rem;
  border: 1px solid #fb5e0e;
  border-radius: 10px;
  background-color: #fb5e0e;
  width: 300px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  height: 40px;
  font-size: 1rem;
}

.nav-links {
  display: none;
  flex-direction: column;
  align-items: center;
  list-style: none;
  width: 100%;
  margin: 1rem 0;
}

.nav-links.open {
  display: flex;
}

.nav-links li {
  margin: 1rem;
}

.nav-item {
  text-decoration: none;
  color: #807d7e;
  font-size: 1.2rem;
  font-weight: 600;
}

.nav-item:hover {
  color: #424242;
}

.icons {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.938rem;
  margin-left: 4rem;
}

.icons a {
  margin: 0.5rem;
  font-size: 1.25rem;
  background-color: #f6efef;
  color: #ada8a9;
  padding: 0.5rem;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .search-bar input {
    width: 500px;
    height: 30px;
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .nav-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .logo-and-menu {
    width: auto;
  }

  .logo {
    margin-top: 0.5rem;
  }

  .menu-toggle {
    display: none;
  }

  .nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
  }

  .nav-links li {
    margin: 0 1rem;
  }

  .nav-links a {
    padding: 1.875rem;
  }

  .icons {
    position: relative;
    justify-content: flex-end;
    margin-right: 2rem;
    margin-bottom: 20px;
  }

  .icons a {
    margin: 0 0.5rem;
    font-size: 1rem;
    background-color: #f6efef;
    color: #ada8a9;
    padding: 0.5rem;
    border-radius: 10px;
  }

  .search-bar {
    width: auto;
    display: flex;
    align-items: center;
    width: 50%;
    margin-left: 1rem;
    margin-right: 2rem;
  }

  .search-bar input {
    margin-top: 1rem;
    width: 400px;
    height: 35px;
    font-size: 1rem;
  }

  .search-icon {
    margin-top: 0.3rem;
    margin-bottom: 0.2rem;
    font-size: 1.1rem;
  }
}
