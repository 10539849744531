.products-total {
  font-size: 2.3;
  text-align: right;
  margin-top: 4rem;
  margin-right: 6rem;
}

.all-products {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.products-title {
  font-size: 2.25rem;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.products-button {
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 140px;
  height: 35px;
  background-color: #fb5e0e;
  color: white;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.products-cards_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product-card {
  margin-bottom: 4rem;
  cursor: pointer;
}

.product-image {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
}

.product-category {
  font-size: 1rem;
  color: #686868;
}

.product-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: #323232;
  margin-top: 0.5rem;
}

.product-title:hover {
  color: #000;
  font-weight: bold;
}

.product-price {
  font-size: 1.3rem;
  color: #fb5e0e;
  font-weight: bold;
  margin-top: 0.5rem;
}

/*All products*/

.all-products-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.all-products-total {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

/*Pagination*/

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}

.pagination li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.pagination .active a {
  background-color: #fb5e0e;
  color: white;
}

@media (min-width: 765px) {
  .products-cards_container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .product-card {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .products-total {
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 3rem;
    margin-left: 6.5rem;
  }

  /*All products*/

  .all-products-title {
    flex-direction: column;
    align-items: start;
    margin-top: 3rem;
    margin-left: 7.5rem;
  }

  /*Pagination*/

  .pagination {
    margin-top: 1rem;
  }

  .pagination li {
    margin: 0 1rem;
  }

  .pagination li a {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .products-title {
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .products-title {
    text-align: left;
    margin-left: 6.5rem;
  }

  .product-card {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .all-products-title {
    margin-left: 6.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .all-products {
    flex-direction: row;
  }

  .products-button {
    margin-top: 3.2rem;
    margin-left: 2rem;
    height: 42px;
    width: 160px;
    font-size: 1rem;
  }
}
